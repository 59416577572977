.shopbybudget {
  width: 100%;

  .shopbybudgetheader {
    border-top: 1px solid #ddd;
    padding-top: 30px;
    padding-bottom: 20px;
    .shopbybudgetheader_text {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      margin: 0;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
      margin-bottom: 0px;
    }
  }
  a {
    width: 100% !important;
    height: auto !important;
    display: block;
    clear: both;
    position: relative !important;
    background: #555;
    span {
      position: relative;
      width: 100% !important;
    }
    img {
      width: 100% !important;
      height: auto !important;
    }
    .h5 {
      position: absolute;
      left: 0;
      text-align: center;
      color: #fff;
      font-size: 25px;
      font-weight: 600;
      padding: 30px 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      padding: 15px 20px;
      color: #fff;
      background: -moz-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -webkit-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -webkit-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -o-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -ms-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
    }
  }
}
